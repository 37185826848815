<template>
  <v-sheet class="io-filter-bar">
    <div v-if="playingEvent" style="text-align: center;">
      <h3>{{playingEvent.title}}</h3>
      <v-divider></v-divider>
      <h4>{{$t('tags.tags')}}</h4>
      <!-- {{tag_groups}} -->
      <div style="overflow-y: auto;" v-if="!$vuetify.breakpoint.mobile">
        <v-chip
          x-small
          outlined
          v-for="tag in playingEvent.tags"
          :key="tag.id"
          color="primary"
          label
        >
          <span style="font-size: 0.8em; margin-right: 5px;">
            {{ group_name(tag) }}
          </span>
          {{ tag.name }}
        </v-chip>
      </div>
    </div>
    <v-divider></v-divider>
    <div style="flex-grow: 1; overflow-y: auto; padding-top: 10px;">
      <div
        v-for="game in events"
        :key="game.id"
      >
        <div class="io-game">
          <div class="io-game-title">
            {{ game.title ? game.title.toUpperCase() : `${game.home_short_name} - ${game.away_short_name}` }}
          </div>
          <!-- {{game}} -->
          <div
            v-for="(clip) in game.clips"
            class="io-game-clip"
            :class="{'chosen': isChosen(clip)}"
            :key="clip.id + 'gamejou'"
            @click="$emit('openEvent', {...clip, video: game})"
          >
            <v-icon style="float: left; padding-top: 2px;" class="pl-4" size="18">mdi-play-circle-outline</v-icon>
            {{ clip.title }}
          </div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <Map
      ref="map"
      v-show="showMap(base)"
      v-for="base in currentClipUniqueBases"
      :key="base.id + 'clipmap'"
      :base="base"
      :value="pointsByBaseId(base.id)"
      :maxHeight="300"
      :maxWidth="300"
      :disableClicks="true"
      :hideButtons="true"
      @open_clip_by_id="openClipById($event)"
    ></Map>
    <div style="text-align: center; border-top: 1px solid lightgrey;">
      <div style="display: flex;">
        <v-btn text disabled>
          Map
        </v-btn>
        <v-btn
          v-show="currentClipUniqueBases.length > 1"
          small
          text
          v-for="base in currentClipUniqueBases"
          :key="'chooser' + base.id"
          @click="chosenMapBaseId = base.id"
          :color="chosenMapBaseId == base.id ? 'primary' : ''"
        >
          {{base.id}}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mt-1" @click="downloadMap()" small text>
          <v-icon>mdi-download-outline</v-icon>
        </v-btn> -->
        <v-btn class="mt-1" @click="mapVisible = !mapVisible" small text>
          <v-icon v-if="mapVisible">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Map from '../maps/Map.vue'

export default {
  components: { Map },
  props: ['events', 'playingEvent', 'leagueFilter'],
  data: () => ({
    chosenMapBaseId: null,
    mapVisible: true
  }),
  created() {
    if(this.leagueFilter) this.initLeagueTags()
    else this.initTags()
    this.chosenMapBaseId = this.currentClipUniqueBases[0]?.id
  },
  methods: {
    isChosen(clip) {
      return this.playingEvent?.id == clip?.id
    },
    group_name(tag) {
      return this.tag_groups.find(t => t.id == tag.group_id)?.group_name
    },
    pointsByBaseId(id) {
      return this.allPoints?.filter(p => p.map_base_id == id)
    },
    showMap(base) {
      return (this.chosenMapBaseId == base.id || this.currentClipUniqueBases.length < 2) && this.mapVisible
    },
    point_clicked(e) {
      alert(JSON.stringify(e))
    },
    openClipById(id) {
      this.events.forEach(game => {
        game.clips.forEach(clip => {
          if(clip.id == id) {
            this.$emit('openEvent', {...clip, video: game})
            return
          }
        })
      })
    },
    downloadMap() {
      this.$refs.map[0].downloadMap()
    },
    ...mapActions('tag', [
      'initTags',
      'initLeagueTags'
    ])
  },
  computed: {
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    allPoints() {
      let pointsArr = this.events?.map(e => e.clips?.map(c => c.points)).flat(Infinity)
      return pointsArr
    },
    currentClipUniqueBases() {
      let bases = []
      this.allPoints.forEach(p => {
        if(!bases.map(b => b.id).includes(p.map_base_id)) {
          let base = {
            id: p.map_base_id,
            url: p.url
          }
          bases.push(base)
        }
      })

      return bases
    },
  }
}
</script>

<style lang="scss" scoped>
  .io-filter-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid lightgrey;
  }

  .io-game {    
    &-title {
      color: grey;
      text-align: center;
      font-size: 1.2em;
    }

    &-clip {
      text-align: center;
      margin: 0px 5px 0px 5px;
      border-radius: 4px;
      border-left: 5px solid rgb(255, 255, 255, 0);

      &:hover {
        background-color: rgb(96, 164, 188);
        cursor: pointer;
      }
    }
  }

  .chosen {
    border-left: 5px solid rgb(76, 133, 154);
  }

  .v-chip {
    &__content {
      display: flex !important;
      flex-direction: column !important;
    }
  } 
</style>