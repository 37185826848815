<template>
  <div 
    class="io-filter-cont"
    :style="`flex-direction: ${!$vuetify.breakpoint.mobile ? 'row' : 'column'}`"
  >
    <div style="position: relative;" v-if="presentation && $vuetify.breakpoint.mobile">
      <v-btn 
        class="fullscreen-back"
        small
        tonal
        @click="switchItem('filter')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-sheet
      class="io-left"
      :style="flexIfPresentation"
    >
      <v-sheet style="height: 40px; padding-top: 0; position: relative" :class="$vuetify.breakpoint.mobile ? 'mobile-bar-hide' : ''">
        
        <v-btn 
          v-if="presentation"
          style="margin-top: -20px; position: absolute; top: 30px; right: 10px" 
          text
          class="ml-2" 
          :x-small="$vuetify.breakpoint.mobile"
          :small="!$vuetify.breakpoint.mobile"
          @click="switchItem('filter')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn v-else
          style="margin-top: -20px;" 
          text 
          class="ml-5" 
          small
          @click="backButton"
          >
          <!-- @click="$router.go(-1)" -->
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-icon
          style="color: black; margin-top: -20px;"
          class="ml-5"
        >
          mdi-filter-outline
        </v-icon>
        <h3 
          class="mt-2 ml-2" 
          style="overflow: hidden; display: inline-block;"
        >
          {{ filter && filter.title ? filter.title : 'No title' }}
        </h3>
      </v-sheet>
      <v-responsive 
        class="io-left-player" 
        :aspect-ratio="$vuetify.breakpoint.mobile ? '16/9' : null" 
        :class="playerIfPresentation"
        style="display: flex; flex-direction: row; align-items: center;" 
        :style="`
          background-color: ${ !$vuetify.theme.dark ? '#000000' : '#ffffff'}, 
          max-width: ${ !$vuetify.breakpoint.mobile ? 'calc(100vw - 356px)' : '' }
        `"
      >
        <editorPlayer
          :height="playerHeight"
          style="margin: auto;"
          v-if="!loading && events && events.length && options"
          :options="options"
          :key="gameKey"
          :filter="true"
          @next="nextEvent()"
          @last="lastEvent()"
        ></editorPlayer>

        <!-- Empty state -->
        <div
          v-else
          style="height: 100%; width: 100%; background-color: black; display: flex; justify-content: center; align-items: center;"
        >
          <div v-if="noevents" style="color: white; font-size: 3em; text-align: center;">
            {{$t('filter.no_results')}}
            <div>
              <v-btn v-if="!presentation" @click="backButton">
                <v-icon>mdi-arrow-left</v-icon>
                {{$t('back')}}
              </v-btn>
              <v-btn v-else @click="switchItem('filter')">
                <v-icon>mdi-close</v-icon>
                {{$t('back')}}
              </v-btn>
            </div>
          </div>
        </div>

      </v-responsive>
      <v-sheet style="padding-inline: 5px" :class="$vuetify.breakpoint.mobile ? 'mobile-bar-hide' : ''">
        <clip-bar
          v-if="options"
          :buffer="options.buffer"
          :end_buffer="options.end_buffer"
          :starttime="options.starttime"
          :endtime="options.endtime"
        ></clip-bar>
        <bar
          v-if="!$vuetify.breakpoint.mobile"
          :filter="filter"
          @apply="openEvent(playingEventFull)"
        ></bar>
      </v-sheet>
    </v-sheet>

    <!-- Item list -->
    <div
      :class="presentation ? 'io-right-prese' : 'io-right'"
      :style="`width: ${$vuetify.breakpoint.mobile ? '100%' : '300px !important'};`"
      style="overflow-y: auto;"
    >
      <filter-bar
        v-if="options"
        @openEvent="openEvent($event)"
        :leagueFilter="filter.search_games"
        :events="events"
        :playingEvent="playingEventFull"
      ></filter-bar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FilterBar from '../components/filters/FilterBar.vue'
import editorPlayer from '../components/video/ClipEditor/EditorPlayer.vue'
import Bar from '../components/video/player/Bar.vue'
import ClipBar from '../components/video/player/ClipBar.vue'
export default {
  props: ['presentation'],
  components: { editorPlayer, Bar, ClipBar, FilterBar },
  data: () => ({
    options: null,
    playingEventFull: null,
    gameKey: 0,
    noevents: false
  }),
  created() {
    if(this.$route.query.filter) {
      this.startGettingStuffNoSave(this.$route.query.filter)
    } else {
      this.startGettingStuff()
    }
  },
  mounted() {
  window.addEventListener('resize', () => {
    this.UPDATE_SCALE()
  })
  },
  computed: {
    ...mapGetters('filter', [
      'loading',
      'events',
      'eventsFlat',
      'buffer',
      'end_buffer',
      'filter',
      'eventById'
    ]),
    flexIfPresentation() {
      return this.$vuetify.breakpoint.mobile ? 'flex-grow: 0 !important' : 'flex-grow: 1 !important'
    },
    playerIfPresentation() {
      return this.$vuetify.breakpoint.mobile ? 'mobile-video': 'desktop-video'
    },
    playerHeight() {
      if (this.presentation) return this.$vuetify.breakpoint.mobile ? 'height: 100%' : 'height: calc(90vh - 165px)'
      return this.$vuetify.breakpoint.mobile ? 'height: 100%' : 'height: calc(100vh - 165px)'
    }
  },
  methods: {
    ...mapActions('filter', [
      'getFilterData',
      'getFilterDataNoSave',
    ]),
    ...mapActions('presentation', [
      'switchItem',
    ]),
    ...mapMutations('filter', [
      'SET_PLAYING_EVENT'
    ]),
    ...mapMutations('draw', [
    'UPDATE_SCALE'
    ]),
    backButton() {
      if(this.$route.query.from) {
        this.$router.push(this.$route.query.from)
        return
      }
      if(this.$route.path.includes('library')){
        this.$router.replace('/library')
        return
      }
      if(this.$route.path.includes('presentation')) {
        this.switchItem('filter')
      }
      this.$router.replace('/filters')
    },
    openFirstEvent() {
      if(!this.eventsFlat.length) {
        this.noevents = true
        return
      }

      this.openEvent(this.eventsFlat[0])
    },
    openEvent(event) {
      this.options = {
        starttime: event.starttime - this.buffer,
        endtime: event.is_point ? event.starttime + this.end_buffer : event.endtime + this.end_buffer,
        source: event.video.mp4_url || event.video.video_url || event.video.original_url,
        mimeType: event.video.video_type == 'hls' ? 'application/x-mpegURL' : 'video/mp4',
        buffer: 0
      }
      
      this.playingEventFull = event
      this.SET_PLAYING_EVENT(event)

      this.$nextTick(() => {
        this.gameKey++
      })
      if(this.presentation) return;
      this.$router.replace({ query: { ...this.$route.query, event_id: event.id }})
    },
    nextEvent() {
      let i = this.eventsFlat.findIndex(c => c.id == this.playingEventFull.id)

      if(this.eventsFlat.length - 1 > i) {
        this.openEvent(this.eventsFlat[i + 1])
      } else {
        this.openFirstEvent()
      }
    },
    lastEvent() {
      let i = this.eventsFlat.findIndex(c => c.id == this.playingEventFull.id)

      if(i > 0) {
        this.openEvent(this.eventsFlat[i - 1])
      } else {
        this.openEvent( this.eventsFlat[this.eventsFlat.length - 1] )
      }
    },
    startGettingStuff() {
    let id;
    if(this.presentation) id = this.$route.query.event_id;
    else id = this.$route.params.id;

      this.getFilterData({
        id,
        limit: 5,
        index: 0
      })
      .then(() => {
        this.$nextTick(() => {
          let ev = this.eventById(this.$route.query.event_id)

          if(!ev) this.openFirstEvent()
          else this.openEvent(ev)
        })
      })
      .catch(e => console.log(e))
    },
    startGettingStuffNoSave(filter) {
      console.log(filter)
      this.getFilterDataNoSave({
        filter
      })
      .then(() => {
        this.$nextTick(() => {
          this.openFirstEvent()
        })
      })
      .catch(e => console.log(e))
    }
  }
} 
</script>

<style lang="scss" scoped>
  .io-filter-cont {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .io-left {
    display: flex;
    background-color: black;
    flex-direction: column;

    &-player {
      flex-grow: 1;
      display: flex;
    }
  }

  .io-right {
    height: 100vh;
    // width: 300px !important;
    overflow-x: hidden;
  }
  .io-right-prese {
    width: 300px;
  }
  .desktop-video {
    // height: calc(90vh - 165px);
    height: calc(90vh - 165px);
  }
  .fullscreen-back {
    display: none;
  }

  // @media only screen and (max-width: 1200px) and (min-width: 960px) {
  // .io-right-prese {
  //   width: 250px;
  // }
  // .io-left {
  //   width:55vw;
  // }
  // }
  @media (orientation: landscape) {
    .mobile-video {
      height: 90vh;
    }
    .mobile-bar-hide {
      display: none;
    }
    .fullscreen-back {
      display: inline; 
      position: absolute; 
      top: 2vh;
      right: 2vh; 
      z-index: 1;
    }
  }
  @media (orientation: portrait) {
  // .mobile-video {
  //   height: 100%;
  // }
  }
</style>